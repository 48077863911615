import React, {memo} from 'react';

import Barcode from 'react-barcode';

import sofiifka from './sofiivka.jpg';
import novaSofiifka from './nova_sofiivka.jpg';
import dinoSofia from './dinosofia.jpg';
import vata from './vata.jpg';
import './styles.css';

const Ticket = ({number, service, cost, valid_until, park}) => {

    const logoRef = {
        1: novaSofiifka,
        2: sofiifka,
        3: dinoSofia,
    };

    const readableDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${day}.${month}.${year}`;
    };

    if (service.id === 22) {
        return (
            <div className="ticket">
                <img id="vata-logo" src={vata} alt="logo"/>
                <h2 className="vata-header"><b>{service.name}</b></h2>
                <div className='barcode-wrapper barcode-wrapper--vata'>
                    <Barcode
                        value='33751339'
                        textPosition='bottom'
                        format='CODE128B'
                        font='GothamPro'
                        fontSize={28}
                    />
                </div>
                <p>www.dinosofia.org</p>
                <div className="bottom-spacer"/>
            </div>
        )
    }

    return (
        <div className="ticket">
            <img id="ticket-logo" src={logoRef[park]} alt="logo"/>
            <h2 className="ticket-header"><b>ВХІДНИЙ КВИТОК</b></h2>
            <h2 className="ticket-service">&laquo;{service.name}&raquo;</h2>
            {cost > 0 && (
                <div className="ticket-amount">
                    <span>{cost} грн.</span>
                </div>
            )}
            <div className='barcode-wrapper'>
                <Barcode
                    value={number}
                    textPosition='bottom'
                    format='CODE128B'
                    font='GothamPro'
                    fontSize={28}
                />
            </div>
            <p>Використати до {readableDate(valid_until)}</p>
            <p>Зберігати до кінця відвідування.</p>
            {park === 3 ? (
                <>
                    <p>Квиток поверненню не підлягає</p>
                    <p>Телефон: (067) 411 00 55</p>
                    <p>www.dinosofia.org</p>
                </>
            ) : <p>www.sofievka.org</p>}
            <div className="bottom-spacer"/>
        </div>
    )
};

export default memo(Ticket);