import React from 'react';

import './styles.css';

const Receipt = ({receipt, toPrint}) => {
    const readableDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };
    return (
        <div className={`receipt ${toPrint && 'receipt-printable'}`}>
            <p className='receipt-header'><b>Квитанція # (ID) {receipt.ext_transaction_id}</b></p>
            <p className='receipt-row'>ТОВ "СВІФТ ГАРАНТ", ЄДРПОУ 39859339</p>
            <p className='receipt-row'>м. Київ, провулок Новопечерський, 5</p>
            <p className='receipt-row'><b>ПТКС: </b> {receipt.kiosk_id}</p>
            <p className='receipt-row'><b>Адреса:</b> {receipt.address || ''}</p>
            <p className='receipt-row'><b>Дата та час: </b>{readableDateTime(receipt.timestamp)}</p>
            <p className='receipt-row'><b>ПЛАТНИК:</b></p>
            <p className='receipt-row'><b>Отримано:</b> {receipt.amount.toFixed(2)} грн.</p>
            <p className='receipt-row'><b>Сума переказу:</b> {receipt.amount_without_commission.toFixed(2)} грн.</p>
            <p className='receipt-row'><b>Комісія:</b> {receipt.commission.toFixed(2)} грн.</p>
            <p className='receipt-row'>
                <b>ОТРИМУВАЧ:</b> {receipt.recipient_name} р/р {receipt.recipient_account} в {receipt.recipient_bank_name}
            </p>
            <p className='receipt-row'><b>ЄДРПОУ: </b>{receipt.recipient_okpo}</p>
            <p className='receipt-row'><b>Вид операції: </b>оплата за послуги/товари</p>
            <p className='receipt-row'><b>Призначення: </b>{receipt.recipient_trans_dest}</p>
            {receipt.payment_system_id && (
                <>
                    {/*<p className='receipt-row'><b>Код транз.: </b>{receipt.payment_system_id} в ВПС "MONEYCOM"</p>*/}
                    <p className='receipt-row'>ТОВ "СВІФТ ГАРАНТ", ЄДРПОУ 39859339</p>
                    <p className='receipt-row'>м. Київ, провулок Новопечерський, 5</p>
                </>
            )}
            <p className='receipt-row'>Послуги надані за договором оферти</p>
            {receipt.rrn && (
                <>
                    <p className='receipt-row'>Карткова ПС: {receipt.issuer_name}</p>
                    <p className='receipt-row'>Еквайр: Ощадбанк, Карта: {receipt.card_number}</p>
                    <p className='receipt-row'>Код авт: {receipt.auth_code}, Терм еквайра: {receipt.pos_id}</p>
                    <p className='receipt-row'>Код транз. в еквайра: {receipt.rrn}</p>
                </>
            )}
            <p className='receipt-row'>Довідка: (096/099/073) 505-57-57</p>
            <p className='receipt-row'>Перевірка стану платежу city24.ua</p>
            <p className='receipt-row'>Telegram @City24Ua_bot</p>
        </div>
    );
};

export default Receipt;