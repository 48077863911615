import React, {useState, useEffect, useContext, memo} from "react";

import {Row, Col, Jumbotron, Button, FormControl} from 'react-bootstrap';
import {UserContext} from "../context";

const RegisterCreationPanel = ({onCreateButtonClick}) => {
    const [initialBalance, setInitialBalance] = useState('');
    const user = useContext(UserContext);

    // useEffect(() => {
    //     const cashDesk = localStorage.getItem('каса');
    //     if (cashDesk) {
    //         getInitialBalance(cashDesk);
    //     }
    // }, []);

    const getInitialBalance = async (cashDesk) => {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`/payment/cash/cash_desk/${cashDesk}/`, {
            method: 'GET',
            headers: {'Authorization': `Token ${token}`},
        });
        if (response.status === 200) {
            const responseJson = await response.json();
            setInitialBalance(responseJson.balance);
        }
    };

    const initialBalanceIsValid = () => {
        const integerInitialBalance = parseInt(initialBalance);
        return !Number.isNaN(integerInitialBalance) && integerInitialBalance >= 0;
    };

    return (
        <Row>
            <Col>
                <Jumbotron>
                    <h1>Відкрита зміна відсутня!</h1>
                    <p>Введіть суму у поле "Службове внесення" та натисніть кнопку "Відкрити" для створення нової зміни.</p>
                    <Row>
                        <Col sm={3}>
                            <FormControl
                                type="number"
                                placeholder="Службове внесення"
                                value={initialBalance}
                                onChange={e => setInitialBalance(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Button
                                onClick={() => onCreateButtonClick(initialBalance, user.id)}
                                variant="dark"
                                disabled={!initialBalanceIsValid()}
                            >Відкрити</Button>
                        </Col>
                    </Row>
                </Jumbotron>
            </Col>
        </Row>
    )
};

export default memo(RegisterCreationPanel);
