import React, {memo} from 'react';

import {
    Row,
    Col,
    Button,
} from 'react-bootstrap';

const AddFormItemButton = ({onClick}) => (
    <Row>
        <Col>
            <div className='d-flex justify-content-end'>
                <Button
                    variant="link"
                    onClick={onClick}
                >Додати послугу</Button>
            </div>
        </Col>
    </Row>
);

export default memo(AddFormItemButton, () => true);
