import React, {useEffect, useState, memo} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {Row, Col, Button} from "react-bootstrap";

import Ticket from './Ticket';
import Receipt from './Receipt';
import TicketMenu from './Ticket/TicketMenu';
import ReceiptMenu from './Receipt/ReceiptMenu';

const PaymentDetails = ({paymentId, onCancelPayment}) => {
    const location = useLocation();
    const [tickets, setTickets] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [checkboxReceipt, setCheckboxReceipt] = useState(null);
    const [ticketsToPrint, setTicketsToPrint] = useState([]);
    const [toPrintReceipt, setToPrintReceipt] = useState(false);

    const autoPrint = parseInt(new URLSearchParams(location.search).get('print'));

    useEffect(() => {
        (async () => {
            const token = window.sessionStorage.getItem('token');
            const response = await fetch(`/payment/cash/?payment_id=${paymentId}`, {
                headers: {'Authorization': `Token ${token}`,}
            });
            if (response.status === 200) {
                const responseJson = await response.json();
                console.log(responseJson)
                await setTickets(responseJson.tickets);
                await setTicketsToPrint(responseJson.tickets);
                await setReceipts(responseJson.receipts);
                await setCheckboxReceipt(responseJson.checkbox_receipt);
                if (autoPrint) {
                    printTickets();
                }
            }
        })();
    }, []);

    const onPrintButtonPress = () => printTickets(tickets);

    const printTickets = () => window.print();

    const printTicket = async (ticket) => {
        await setTicketsToPrint(tickets.filter(t => t.number === ticket.number));
        window.print();
        await setTicketsToPrint(tickets);
    };

    const printReceipt = async () => {
        await setTicketsToPrint([]);
        await setToPrintReceipt(true);
        window.print();
        await setToPrintReceipt(false);
        await setTicketsToPrint(tickets);
    };

    const cancelTicket = async (ticket) => {
        if (window.confirm('Ви дійсно бажаєте анулювати квиток?')) {
            const token = window.sessionStorage.getItem('token');
            const response = await fetch(`/ticket/${ticket.number}/`, {
                method: 'DELETE',
                headers: {'Authorization': `Token ${token}`,}
            });
            if (response.status === 200) {
                window.location.replace(window.location.pathname);
            } else {
                const responseJson = await response.json();
                window.alert(responseJson);
            }
        }
    };

    return (
        <>
            <div
                className='d-flex justify-content-end'
                style={{paddingBottom: '15px'}}
            >
                <Button
                    variant="outline-secondary"
                    onClick={onPrintButtonPress}
                >Друк</Button>
                <Button
                    variant="outline-danger"
                    onClick={() => onCancelPayment(paymentId)}
                >Відміна</Button>
            </div>
            <Switch>
                <Route exact path='/cashier/payment/:id' render={() => (
                    <Row className='tickets-wrapper'>
                        {ticketsToPrint.map(ticket => (
                            <Col
                                sm={6}
                                md={4}
                                lg={3}
                                key={ticket.number}
                            >
                                <Ticket
                                    {...ticket}
                                />
                                <TicketMenu
                                    onPrintButtonPress={() => printTicket(ticket)}
                                    onCancelTicketPress={() => cancelTicket(ticket)}
                                    // showCancelButton={ticket.status !== 'Анульований'}
                                />
                            </Col>
                        ))}
                        {receipts.map(receipt => (
                            <Col
                                sm={6}
                                md={4}
                                lg={3}
                                key={receipt.ext_transaction_id}
                            >
                                <Receipt
                                    receipt={receipt}
                                    toPrint={toPrintReceipt}
                                    onPrintButtonPress={printReceipt}
                                />
                                <ReceiptMenu onPrintButtonPress={printReceipt}/>
                            </Col>
                        ))}
                        {checkboxReceipt && checkboxReceipt.html && (
                            <Col
                                sm={6}
                                md={4}
                                lg={3}
                            >
                                <div className='receipt receipt-printable'
                                     dangerouslySetInnerHTML={{__html: checkboxReceipt.html}}
                                />
                            </Col>
                        )}
                    </Row>
                )}/>
            </Switch>
        </>
    )
};

export default memo(PaymentDetails);
