import React, {useState, useContext} from "react";
import {Row, Col, Form} from "react-bootstrap";

import FormItem from './FormItem';
import ServicesSets from './ServicesSets';
import AddFormItemButton from './AddFormItemButton';
import SubmitFormButton from './SubmitFormButton';
import Cart from './Cart';
import {PaymentReferenceContext, UserContext} from "../../context";


const PaymentForm = ({cashDesk, isProcessingPayment, onSubmit}) => {
    const generateServiceItemId = () => Math.floor(Math.random() * 10000);

    const {servicesList, exemptions} = useContext(PaymentReferenceContext);
    const user = useContext(UserContext);
    const [items, setItems] = useState([{
        id: generateServiceItemId(),
        service: 0,
        amount: null,
    }]);
    const [paymentType, setPaymentType] = useState(null);

    const formItemsIsValid = (_items) => _items.length > 0 && _items.every(itemIsValid) && isOneParkItems(_items);

    const filterValidItems = (_items) => _items.filter(itemIsValid);

    const itemIsValid = (item) => {
        if (item.service === 0 || !item.amount || item.amount <= 0) {
            return false;
        }
        const selectedService = servicesList.find(s => s.id === item.service);
        if (!selectedService) {
            return false;
        }
        if (Number.isInteger(item.children) && item.children < 0) {
            return false;
        }
        if (selectedService.require_comment && !item.comment) {
            return false;
        }
        if (selectedService.is_excursion && (item.excursionAdult <= 0 && item.excursionChildren <= 0)) {
            return false;
        }
        return true;
    };

    const isOneParkItems = (items) => {
        const parks = [];
        items.map(i => {
            const selectedService = servicesList.find(s => s.id === i.service);
            if (!parks.includes(selectedService.park)) {
                parks.push(selectedService.park);
            }
        });
        return parks.length === 1;
    };

    const addItemToForm = () => setItems(items => [...items, {
        id: generateServiceItemId(),
        service: 0,
        amount: null,
        exemption: exemptions[0].id,
        comment: '',
    }]);

    const removeItemFromForm = (itemId) => {
        if (isProcessingPayment) return;
        const filteredItems = items.filter(i => i.id !== itemId);
        setItems(filteredItems);
    };

    const onFormItemFieldChange = (itemId, field, value) => {
        const calcExcursionAmount = (serviceId, adultCount, childrenCount) => {
            const mapping = {
                24: {'adult': 70, 'children': 40},
                9: {'adult': 40, 'children': 25},
                21: {'adult': 40, 'children': 25},
                30: {'adult': 100, 'children': 70},
            };
            try {
               const adultPrice = mapping[serviceId]['adult'] || 1;
               const childrenPrice = mapping[serviceId]['children'] || 1;
               return (adultPrice * adultCount || 0) + (childrenPrice * childrenCount || 0);
            } catch {
                return 0
            }
        }
        if (isProcessingPayment) return;
        setItems(items => items.map(item => {
            if (item.id === itemId) {
                if (['service', 'amount', 'exemption'].includes(field)) {
                    value = parseInt(value);
                }
                if (['excursionAdult', 'excursionChildren'].includes(field)) {
                    const adultCount = field === 'excursionAdult' ? value : item.excursionAdult;
                    const childrenCount = field === 'excursionChildren' ? value : item.excursionChildren;
                    const amount = calcExcursionAmount(item.service, adultCount, childrenCount);
                    return {...item, [field]: value, amount};
                }
                return {...item, [field]: value};
            }
            return {...item};
        }));
    };

    const onServicesSetSelect = (servicesSetItems) => {
        servicesSetItems = servicesSetItems.map(serviceSet => ({
            id: generateServiceItemId(),
            exemption: exemptions[0].id,
            ...serviceSet,
        }));

        setItems(items => {
            const firstItem = items[0];
            const firstItemIsInitial = firstItem && firstItem.service === 0 && firstItem.amount === null;
            return firstItemIsInitial ? servicesSetItems : [...items, ...servicesSetItems];
        });
    };

    const onFormSubmit = async () => {
        if (isProcessingPayment) return;
        const isValid = formItemsIsValid(items);
        if (isValid && paymentType && !isProcessingPayment) {
            await onSubmit({
                services: items,
                cash_amount: calculateItemsTotalCost(),
                payment_type: paymentType,
                user_id: user.id,
            });
        }
    };

    const calculateItemsTotalCost = () => {
        return items.reduce((accumulator, item) => {
            const service = servicesList.find(s => s.id === item.service);
            if (!service) {
                return accumulator;
            }
            if (service.type === 'floating') {
                return accumulator + item.amount;
            }
            return accumulator + (service.price * item.amount);
        }, 0)
    };

    const countTotalItems = () => {
        return items.reduce((accumulator, item) => {
            const service = servicesList.find(s => s.id === item.service);
            if (!service) {
                return accumulator;
            }
            if (service.type === 'fixed') {
                const {children} = item;
                return Number.isInteger(children) ? accumulator + item.amount + children : accumulator + item.amount;
            }
            return accumulator + 1;
        }, 0);
    };

    if (!servicesList) {
        return null;
    }

    const validItems = filterValidItems(items);
    const totalItemsCount = countTotalItems();
    const formIsValid = formItemsIsValid(items);
    const itemsTotalCost = calculateItemsTotalCost(validItems);
    return (
        <>
            <ServicesSets
                onServicesSetSelect={onServicesSetSelect}
            />
            <Row>
                <Col md={9}>
                    <Form>
                        {items.map(item => (
                            <FormItem
                                key={item.id}
                                {...item}
                                cashDesk={cashDesk}
                                onFieldChange={onFormItemFieldChange}
                                onDeleteIconClick={removeItemFromForm}
                            />
                        ))}
                        <AddFormItemButton onClick={addItemToForm}/>
                    </Form>
                </Col>
                <Col md={3}>
                    <Cart
                        items={totalItemsCount}
                        paymentType={paymentType}
                        total={itemsTotalCost}
                        onPaymentTypeChange={setPaymentType}
                    />
                    <SubmitFormButton
                        disabled={!formIsValid || !paymentType || totalItemsCount > 40 || itemsTotalCost > 4999 || isProcessingPayment}
                        onClick={onFormSubmit}
                    />
                </Col>
            </Row>
        </>
    )
};

export default PaymentForm;

