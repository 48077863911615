import React from "react";
import {Col, Row, Spinner} from "react-bootstrap";

const Loader = () => (
    <Row>
        <Col>
            <div
                className='d-flex justify-content-center align-items-center'
                style={{minHeight: '360px'}}
            >
                <Spinner animation="border"/>
            </div>
        </Col>
    </Row>

);

export default Loader;
