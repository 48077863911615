import React, {memo, useContext, useState} from 'react';
import {Badge, ListGroup, FormControl, Form} from "react-bootstrap";
import {PaymentReferenceContext} from "../../context";

const Cart = ({items, total, paymentType, onPaymentTypeChange}) => {
    const [cash, setCash] = useState(0);
    const {paymentTypes} = useContext(PaymentReferenceContext);

    const rest = cash > 0 ? cash - total : 0;
    return (
        <>
            <h4>Корзина</h4>
            <ListGroup>
                <ListGroup.Item>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>Квитків</span>
                        <Badge
                            pill
                            variant={`${items <= 40 ? 'secondary' : 'danger'}`}
                        >{items} шт.</Badge>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>До сплати</span>
                        <Badge
                            pill
                            variant={`${total <= 4999 ? 'secondary' : 'danger'}`}
                        >{total} грн.</Badge>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>Готівка</span>
                        <FormControl
                            style={{width: '150px'}}
                            value={cash}
                            onChange={e => setCash(e.target.value)}
                            onFocus={e => e.target.select()}
                        />
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>Решта</span><Badge pill variant="secondary">{rest > 0 ? rest : 0} грн.</Badge>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='d-flex justify-content-end'>
                        {paymentTypes && paymentTypes.map(p => (
                            <Form.Check
                                inline
                                checked={paymentType === p.id}
                                key={p.id}
                                label={p.name}
                                value={p.id}
                                type='radio'
                                id={`exemption-radio-${p.id}`}
                                name={`payment-types-radio`}
                                onChange={e => onPaymentTypeChange(parseInt(e.target.value))}
                            />
                        ))}
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </>
    )
};

export default memo(Cart);
