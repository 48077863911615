import React from 'react';

import ReportFilterFormSubmitButton from '../components/Report/ReportFilterFormSubmitButton';
import {Col, Container, Form, Row, Table} from "react-bootstrap";
import Loader from "../components/Loader";


export default class CashierReportPage extends React.PureComponent {
  static dateToString = (dt) => {
    const year = dt.getFullYear();
    const month = `${dt.getMonth() + 1}`.padStart(2, '0');
    const day = `${dt.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  state = {
    data: null,
    isLoading: false,
    filters: {
      dateFrom: CashierReportPage.dateToString(new Date()),
      dateUntil: CashierReportPage.dateToString(new Date()),
    },
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    this.setState({isLoading: true});
    const {dateFrom, dateUntil} = this.state.filters;
    let url = `/stats/cashier/?date_from=${dateFrom}&date_until=${dateUntil}`;

    const token = window.sessionStorage.getItem('token');
    const response = await fetch(url, {
      headers: {'Authorization': `Token ${token}`},
    });
    const responseJson = await response.json();
    this.setState({data: responseJson, isLoading: false});
  };

  render() {
    const {data, filters} = this.state;
    if (data === null) return Loader();
    return (
      <Container fluid>
        <Row>
          <Col lg={8} xl={10}>
            <h5>Сума коштів отримана за реалізовані квитки</h5>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{marginTop: '16px'}}
              className='payment-types-report'
            >
              <thead>
              <tr>
                <th>Послуга</th>
                <th>Готівка</th>
                <th>Безготівка</th>
                <th>Всього</th>
              </tr>
              </thead>
              <tbody>
              {Object.keys(data).map(service => (
                <tr key={service}>
                  <td><b>{service}</b></td>
                  <td>{data[service]['1']['sum']}</td>
                  <td>{data[service]['4']['sum']}</td>
                  <td>{data[service]['1']['sum'] + data[service]['4']['sum']}</td>
                </tr>
              ))}
              </tbody>
            </Table>

            <h5>Кількість реалізованих квитків</h5>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{marginTop: '16px'}}
              className='payment-types-report'
            >
              <thead>
              <tr>
                <th>Послуга</th>
                <th>Готівка</th>
                <th>Безготівка</th>
                <th>Всього</th>
              </tr>
              </thead>
              <tbody>
              {Object.keys(data).map(service => (
                <tr key={service}>
                  <td><b>{service}</b></td>
                  <td>{data[service]['1']['count']}</td>
                  <td>{data[service]['4']['count']}</td>
                  <td>{data[service]['1']['count'] + data[service]['4']['count']}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Col>
          <Col lg={4} xl={2}>
            <Form>
              <Form.Group>
                <Form.Label htmlFor='report-date-from'>Від:</Form.Label>
                <Form.Control
                  placeholder='Від'
                  type={'date'}
                  value={filters.dateFrom}
                  id='report-date-from'
                  min='2023-04-01'
                  max='2130-12-31'
                  onChange={e => this.setState({filters: {...filters, dateFrom: e.target.value}})}
                />
              </Form.Group>
              <hr/>
              <Form.Group>
                <Form.Label htmlFor='reports-date-until'>До:</Form.Label>
                <Form.Control
                  placeholder='До'
                  type={'date'}
                  value={filters.dateUntil}
                  id='report-date-until'
                  min='2023-04-01'
                  max='2130-12-31'
                  onChange={e => this.setState({filters: {...filters, dateUntil: e.target.value}})}
                />
              </Form.Group>
              <ReportFilterFormSubmitButton
                disabled={this.state.isLoading}
                onClick={this.getReportData}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}
