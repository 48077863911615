import React, {memo, useContext} from 'react';

import {Button} from "react-bootstrap";

import {PaymentReferenceContext} from '../../context';

const ServicesSets = ({onServicesSetSelect}) => {
    const {serviceSets} = useContext(PaymentReferenceContext);
    return (
        <div>
            {serviceSets && serviceSets.map(serviceSet => (
                <Button
                    key={serviceSet.id}
                    variant="outline-secondary"
                    style={{margin: '4px 8px 8px 0', borderRadius: '36px'}}
                    onClick={() => onServicesSetSelect(serviceSet.items)}
                >{serviceSet.name}</Button>
            ))}
        </div>
    )
};

export default memo(ServicesSets, (prevProps, nextProps) => prevProps.servicesSets === nextProps.servicesSets);