import React from 'react';

import DinoSofiaReport from '../components/DinoSofiaReport';
import ReportFilterForm from '../components/DinoSofiaReport/ReportFilterForm';
import ReportFilterFormSubmitButton from '../components/DinoSofiaReport/ReportFilterFormSubmitButton';
import {Col, Container, Row} from "react-bootstrap";


export default class DinoSofiaReportPage extends React.PureComponent {
    static dateToString = (dt) => {
        const year = dt.getFullYear();
        const month = `${dt.getMonth() + 1}`.padStart(2, '0');
        const day = `${dt.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    state = {
        data: {},
        isLoading: false,
        reference: {},
        filters: {
            dateFrom: DinoSofiaReportPage.dateToString(new Date()),
            dateUntil: DinoSofiaReportPage.dateToString(new Date()),
        },
        includeTickets: false,

    };

    async componentDidMount() {
        await this.getReference();
        await this.getReportData();

        setInterval(this.getReportData, 60000);
    }

    getReference = async () => {
        const token = window.sessionStorage.getItem('token');
        const response = await fetch('/stats/reference/dinosofia/', {
            headers: {'Authorization': `Token ${token}`},
        });
        const reference = await response.json();
        this.setState({reference});
    };

    onChangeFilter = (filters) => {
        this.setState({filters});
    };

    getReportData = async () => {
        this.setState({isLoading: true});
        const {dateFrom, dateUntil} = this.state.filters;
        let url = `/stats/ticket/detailed/dinosofia/?date_from=${dateFrom}&date_until=${dateUntil}`;

        const token = window.sessionStorage.getItem('token');
        const response = await fetch(url, {
            headers: {'Authorization': `Token ${token}`},
        });
        const responseJson = await response.json();
        this.setState({data: responseJson, isLoading: false});
    };

    render() {
        const {data, filters, reference} = this.state;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <ReportFilterForm
                            filters={filters}
                            onChangeFilter={this.onChangeFilter}
                        />
                        <ReportFilterFormSubmitButton
                            disabled={this.state.isLoading}
                            onClick={this.getReportData}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DinoSofiaReport
                            data={data}
                            reference={reference}
                            isLoading={this.state.isLoading}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
