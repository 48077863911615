import React from 'react';

import {Row, Col, Table, Button} from "react-bootstrap";

import Loader from '../components/Loader';

export default class UncollectedRegistersReportPage extends React.Component {
    state = {
        data: null,
    };

    componentDidMount() {
        this.getReportData();
        setInterval(this.getReportData, 30000);
    }

    getReportData = async () => {
        const token = window.sessionStorage.getItem('token');
        const response = await fetch('/stats/registers/uncollected/', {
            headers: {'Authorization': `Token ${token}`},
        });
        const responseJson = await response.json();
        this.setState({data: responseJson});
    };

    onReportClick = async (register) => {
        if (window.confirm(`Ви дійсно бажаєте закрити касету ${register.cash_desk} на суму ${register.amount} грн?.`)) {
            const token = window.sessionStorage.getItem('token');
            const response = await fetch('/stats/registers/uncollected/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({register_id: register.id}),
            });
            if (response.status === 200) {
                this.getReportData();
            } else {
                alert('Неможливо закрити обрану касету.');
            }
        }
    };

    render() {
        const {data} = this.state;
        if (data === null) {
            return <div style={{marginTop: '48px'}}><Loader/></div>
        }
        return (
            <Row>
                <Col>
                    <div>
                        <h5>Незавершені інкасації</h5>
                        {data.map(c => (
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                style={{marginTop: '24px'}}
                                className='services-report'
                            >
                                <tr>
                                    <td><b>Каса</b></td>
                                    <td>{c.cash_desk}</td>
                                </tr>
                                <tr>
                                    <td><b>Касир</b></td>
                                    <td>{c.cashier}</td>
                                </tr>
                                <tr>
                                    <td><b>Дата</b></td>
                                    <td>{c.date}</td>
                                </tr>
                                <tr>
                                    <td><b>Сума</b></td>
                                    <td>{c.amount} грн.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className='d-flex justify-content-center'>
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => this.onReportClick(c)}
                                            >Інкасовано</Button>
                                        </div>
                                    </td>
                                </tr>
                            </Table>
                        ))}
                    </div>
                </Col>
            </Row>
        )
    }
}