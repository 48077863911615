import React, {memo} from "react";
import {
    Row,
    Col,
    Breadcrumb,
    ButtonGroup,
    Button,
    DropdownButton,
    Dropdown
} from "react-bootstrap";

const RegisterMenu = ({
                          register,
                          onNewPaymentButtonPress,
                          onCloseRegisterButtonPress,
                          onXReportButtonPress,
                          onServiceOutgoingButtonpress,
                          onCheckTicketButtonPress,
                      }) => {
    return (
        <Row>
            <Col md={9}>
                <Breadcrumb>
                    <Breadcrumb.Item active><a href='/cashier'>Зміна #{register.id}</a></Breadcrumb.Item>
                    <Breadcrumb.Item active>Касир: <b>{register.cashier}</b></Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col md={3}>
                <ButtonGroup style={{marginTop: '4px', width: '100%'}}>
                    <Button
                        variant="outline-secondary"
                        onClick={onNewPaymentButtonPress}
                    >Новий платіж</Button>
                    <DropdownButton
                        id='dropdown'
                        as={ButtonGroup}
                        title=''
                        variant="outline-secondary"
                    >
                        <Dropdown.Item onClick={onCheckTicketButtonPress}>Перевірити квиток</Dropdown.Item>
                        <Dropdown.Item onClick={onXReportButtonPress}>X звіт</Dropdown.Item>
                        <Dropdown.Item onClick={onServiceOutgoingButtonpress}>Службова видача</Dropdown.Item>
                        <Dropdown.Item onClick={onCloseRegisterButtonPress}>Закрити зміну</Dropdown.Item>
                    </DropdownButton>
                </ButtonGroup>
            </Col>
        </Row>
    )
};

export default memo(RegisterMenu);
