import React, {useState, memo, useEffect} from 'react';
import {AsyncTypeahead} from "react-bootstrap-typeahead";

const AutocompleteField = ({id, searchUrl, value, placeholder, labelKey, searchUrlParams = '', minLength = 0, autoFocus = false, allowNew = false, onSelect, onChange, onBlur}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const token = window.sessionStorage.getItem('token');

    const onSearch = async(q) => {
        setIsLoading(true);
        const response = await fetch(`${searchUrl}${q ? `?q=${q}&` : '?'}${searchUrlParams}`, {
            method: 'GET',
            headers: {'Authorization': `Token ${token}`},
        });
        const responseJson = await response.json();
        setIsLoading(false);
        setOptions(responseJson);
    };

    useEffect(() => {
        if(minLength === 0) {
            (async () => await onSearch(value))();
        }
    }, []);

    const onOptionSelect = (options) => {
        const option = options[0];
        onSelect(option || null);
    }

    return (
        <AsyncTypeahead
            id={id}
            isLoading={isLoading}
            autoFocus={autoFocus}
            allowNew={allowNew}
            minLength={minLength}
            onSearch={onSearch}
            options={options}
            labelKey={labelKey}
            placeholder={placeholder}
            onChange={onOptionSelect}
            onBlur={onBlur}
            onInputChange={onChange}
            useCache={false}
            newSelectionPrefix={'Додати: '}
            emptyLabel={<div>Не знайдено</div>}
            inputProps={{
                id,
            }}
        />
    )

};

export default memo(AutocompleteField, () => true);
