import React, {useContext, memo} from 'react';
import {PaymentReferenceContext} from "../context";
import {Alert} from "react-bootstrap";


const RegisterBalance = ({balance}) => {
    const {paymentTypes} = useContext(PaymentReferenceContext);

    const aggregateBalance = () => {
        let aggregatedBalance = `Служб. внесення: ${balance[0]} грн; `;
        if (!paymentTypes) {
            return aggregatedBalance;
        }
        for (let [key, value] of Object.entries(balance)) {
            const paymentType = paymentTypes.find(t => t.id === parseInt(key));
            if (paymentType) {
                aggregatedBalance += `${paymentType.name}: ${value} грн; `;
            }
        }
        if (balance['ДіноСофія готівка']) {
            aggregatedBalance += `ДіноСофія готівка: ${balance['ДіноСофія готівка']} грн.`
        }
        if (balance[99]) {
            aggregatedBalance += `Служб. видача: ${balance[99]} грн.`
        }
        return aggregatedBalance;
    };
    return (
        <Alert variant='secondary'>
            <b>Сума:</b> {aggregateBalance()}
        </Alert>
    )
};

export default memo(RegisterBalance);