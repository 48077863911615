import React, {memo} from "react";
import {Button} from "react-bootstrap";

const ReportFilterFormSubmitButton = ({disabled, onClick}) => (
    <div className='d-flex justify-content-end' style={{marginBottom: '16px'}}>
        <Button
            disabled={disabled}
            onClick={onClick}
        >ОК</Button>
    </div>
);

export default memo(ReportFilterFormSubmitButton);
