import React, {memo} from "react";
import {Form, Row, Col} from "react-bootstrap";

const ReportFilterForm = ({filters, onChangeFilter}) => {
    const onChangeDateFrom = (e) => onChangeFilter({...filters, dateFrom: e.target.value});

    const onChangeDateUntil = (e) => onChangeFilter({...filters, dateUntil: e.target.value});

    return (
        <div>
            <Form>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor='report-date-from'>Від:</Form.Label>
                            <Form.Control
                                placeholder='Від'
                                type={'date'}
                                value={filters.dateFrom}
                                id='report-date-from'
                                min='2021-08-07'
                                max='2130-12-31'
                                onChange={onChangeDateFrom}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor='reports-date-until'>До:</Form.Label>
                            <Form.Control
                                placeholder='До'
                                type={'date'}
                                value={filters.dateUntil}
                                id='report-date-until'
                                min='2020-07-06'
                                max='2130-12-31'
                                onChange={onChangeDateUntil}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    )
};

export default memo(ReportFilterForm);
