import React, {memo} from "react";

const RegisterPrintReport = ({register}) => {
    const initCashBalance = parseInt(register.balance[0]) || 0;
    const cashCollected = register.balance[1] || 0;
    const accountedBalance = register.accountedBalance || 0;
    const finishCashBalance = initCashBalance + cashCollected - accountedBalance;
    const dinoSofiaBalance = register.balance['ДіноСофія готівка'] || 0;
    return (
        <>
            <div className='register-print'>
                <p># {register.id}</p>
                <p>Касир: {register.cashier}</p>
                <p>Початковий залишок: {initCashBalance} грн.</p>
                <p>Готівка: {cashCollected} грн.</p>
                <p>Здано: {accountedBalance} грн.</p>
                {!Number.isNaN(finishCashBalance) && <p>Залишок після здачі: {finishCashBalance} грн.</p>}
                {dinoSofiaBalance && (
                    <p>ДіноСофія: {dinoSofiaBalance} грн.</p>
                )}
                <p>Дата: {new Date().toLocaleDateString('uk')}</p>
            </div>
            {dinoSofiaBalance > 0 && (
                <div className='register-print'>
                    <p><b>Парк ДіноСофія</b></p>
                    <p>Каса: {register.cash_desk.name}</p>
                    <p>Касир: {register.cashier}</p>
                    <p>Дата: {new Date().toLocaleDateString('uk')}</p>
                    <p>Готівка: {dinoSofiaBalance} грн.</p>
                </div>
            )}
        </>
    )
};

export default memo(RegisterPrintReport);