import React, {memo, useState} from 'react';
import {
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";
import {useHistory} from 'react-router-dom';

const AuthenticationPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const history = useHistory();

    const authenticate = async (e) => {
        e.preventDefault();
        const requestBody = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username, password})
        };

        const response = await fetch('/api-token-auth/', requestBody);
        if (response.status === 200) {
            const responseJson = await response.json();
            window.sessionStorage.setItem('token', responseJson.token);
            history.replace('/');
            document.location.reload();
        } else {
            setError('Неможливо зайти з введеними даними.');
        }
    };

    return (
        <Row>
            <Col>
                <div
                    className='d-flex justify-content-center align-items-center'
                    style={{height: '80vh'}}
                >
                    <Form>
                        <Form.Group>
                            <Form.Control
                                autoFocus={true}
                                placeholder="Логін"
                                value={username}
                                onChange={e => {
                                    setUsername(e.target.value);
                                    error && setError(null);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password"
                                placeholder="Пароль"
                                value={password}
                                onChange={e => {
                                    setPassword(e.target.value);
                                    error && setError(null);
                                }}
                            />
                        </Form.Group>
                        {error && (
                            <Form.Text style={{color: '#DC3545', paddingBottom: '15px'}}>
                                {error}
                            </Form.Text>
                        )}
                        <Button
                            block
                            type="submit"
                            variant="outline-secondary"
                            onClick={authenticate}
                            disabled={!username || !password}
                        >
                            Увійти
                        </Button>
                    </Form>
                </div>
            </Col>
        </Row>
    )
};

export default memo(AuthenticationPage);
