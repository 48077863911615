import React, {memo, useState, useEffect} from 'react';
import {Tabs, Tab} from "react-bootstrap";

import DetailedReport from './DetailedReport';
import AggregatedReport from './AggregatedReport';

const Report = ({data, reference, filters, isLoading, onReportTabChange}) => {
    const [tabKey, setTabKey] = useState('aggregated');

    useEffect(() => {
        onReportTabChange(tabKey);
    }, [tabKey]);

    return (
        <Tabs activeKey={tabKey} onSelect={setTabKey} id="report-tabs">
            <Tab eventKey="aggregated" title="Зведено">
                <AggregatedReport
                    data={data.reports}
                    reference={reference}
                    filters={filters}
                    isLoading={isLoading}
                />
            </Tab>
            <Tab eventKey="detailed" title="Детально">
                <DetailedReport data={data.tickets} isLoading={isLoading} />
            </Tab>
        </Tabs>
    )
};

export default memo(Report);
