import React, {memo} from 'react';

import {Row, Col, Table} from "react-bootstrap";
import Loader from "../Loader";

const DetailedReport = ({data, isLoading}) => {
    if (isLoading) {
        return <Loader />;
    }
    return (
        <Row>
        <Col>
            <Table
                striped
                bordered
                hover
                size="sm"
                style={{marginTop: '16px'}}
            >
                <thead>
                <tr>
                    <th>#</th>
                    <th>Тип квитку</th>
                    <th>Створено</th>
                    <th>Вартість</th>
                    <th>Тип оплати</th>
                    <th>Касир</th>
                    <th style={{width: '12%'}}>Відвідувач</th>
                    <th style={{width: '12%'}}>Пільга</th>
                </tr>
                </thead>
                <tbody>
                {data && data.map(t => (
                    <tr key={t.number}>
                        <td><b>{t.number}</b></td>
                        <td>{t.service}</td>
                        <td>{t.created_at}</td>
                        <td>{t.cost}</td>
                        <td>{t.payment_type}</td>
                        <td>{t.cashier}</td>
                        <td style={{width: '12%'}}>{t.visitor}</td>
                        <td style={{width: '12%'}}>{t.exemption}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Col>
    </Row>
    )
};

export default memo(DetailedReport);
