import React, {useState, useEffect, useRef} from 'react';

import {Row, Col, Form, Button} from "react-bootstrap";

const CheckTicket = () => {
    const [ticketNumber, setTicketNumber] = useState('');
    const [ticket, setTicket] = useState(null);
    const [error, setError] = useState(null);
    const inputEl = useRef(null);

    useEffect(() => {
        ticketNumber.length === 12 && checkTicket();
    }, [ticketNumber]);

    const formatDateTime = (datetime) => datetime.slice(0, 19).replace('T', ' ');

    const checkTicket = async () => {
        if (!ticketNumber) return;
        const token = window.sessionStorage.getItem('token');
        const response = await fetch(`/ticket/${ticketNumber}/`, {
            method: 'GET',
            headers: {'Authorization': `Token ${token}`}
        });
        const responseJson = await response.json();
        if (response.status === 200) {
            setTicket(responseJson);
            setError(null);
        } else {
            setError(responseJson);
            setTicket(null);
        }
    }

    const makeUsedTicket = async (ticketNumber) => {
        const token = window.sessionStorage.getItem('token');
        const response = await fetch(`/ticket/`, {
            method: 'POST',
            headers: {'Authorization': `Token ${token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'number': ticketNumber})
        });
        if (response.status === 200) {
            await checkTicket();
            inputEl.current.focus();
        } else {
            alert('Виникла помилка під час погашення квитку.');
        }

    }

    return (
        <>
            <Row>
                <Col sm={4}>
                    <Form.Control
                        autoFocus
                        placeholder={'Введіть номер квитку'}
                        value={ticketNumber || ''}
                        type={'number'}
                        isValid={ticketNumber.length === 12}
                        onChange={e => setTicketNumber(e.target.value)}
                        onFocus={() => setTicketNumber('')}
                        ref={inputEl}
                    />
                </Col>
                <Col>
                    <Button
                        variant="outline-secondary"
                        onClick={checkTicket}
                    >Перевірити</Button>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <p style={{marginTop: '15px'}}>{error}</p>
                    {ticket && (
                        <>
                            <ul style={{marginTop: '15px'}}>
                                <li><b>Статус:</b> {ticket.status}</li>
                                <li><b>Тип:</b> {ticket.service.name}</li>
                                <li><b>Касир:</b> {ticket.cash_desk}</li>
                                <li><b>Придбано:</b> {formatDateTime(ticket.created_at)}</li>
                                {ticket.used_at && <li><b>Використано:</b> {formatDateTime(ticket.used_at)}</li>}
                                <li><b>Дійсний до:</b> {ticket.valid_until}</li>
                            </ul>
                            {ticket.status === 'Новий' && (
                                <Button
                                    variant="outline-primary"
                                    onClick={() => makeUsedTicket(ticketNumber)}
                                >Погасити</Button>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default CheckTicket;