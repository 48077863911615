import React, {memo} from 'react';
import {Button, ButtonGroup} from "react-bootstrap";


const ReceiptMenu = ({onPrintButtonPress}) => {
    return (
        <div className='receipt-menu'>
            <ButtonGroup size="sm">
                <Button
                    variant="outline-secondary"
                    onClick={onPrintButtonPress}
                >Друк</Button>
            </ButtonGroup>
        </div>
    )
};

export default memo(ReceiptMenu, () => true);
