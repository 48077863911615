import React from 'react';

import Report from '../components/Report';
import ReportFilterForm from '../components/Report/ReportFilterForm';
import ReportFilterFormSubmitButton from '../components/Report/ReportFilterFormSubmitButton';
import {Col, Container, Row} from "react-bootstrap";


export default class ReportPage extends React.PureComponent {
    static dateToString = (dt) => {
        const year = dt.getFullYear();
        const month = `${dt.getMonth() + 1}`.padStart(2, '0');
        const day = `${dt.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    state = {
        data: {},
        isLoading: false,
        reference: {},
        filters: {
            dateFrom: ReportPage.dateToString(new Date()),
            dateUntil: ReportPage.dateToString(new Date()),
            services: JSON.parse(localStorage.getItem('cachedServicesFilter')) || [],
            paymentTypes: JSON.parse(localStorage.getItem('cachedPaymentTypesFilter')) || [],
        },
        includeTickets: false,

    };

    async componentDidMount() {
        await this.getReference();
        await this.getReportData();

        setInterval(this.getReportData, 60000);
    }

    getReference = async () => {
        const token = window.sessionStorage.getItem('token');
        const response = await fetch('/stats/reference/', {
            headers: {'Authorization': `Token ${token}`},
        });
        const reference = await response.json();
        this.setState(state => {
            const filters = {...state.filters};
            if (filters.services.length === 0) {
                filters.services = reference.services.map(s => s.id);
            }
            if (filters.paymentTypes.length === 0) {
                filters.paymentTypes = reference.payment_types.map(pt => pt.id);
            }
            return {reference, filters}
        });
    };

    onChangeFilter = (filters) => {
        this.setState({filters}, () => {
            localStorage.setItem('cachedServicesFilter', JSON.stringify(filters.services));
            localStorage.setItem('cachedPaymentTypesFilter', JSON.stringify(filters.paymentTypes));
        });
    };

    getReportData = async () => {
        this.setState({isLoading: true});
        const {dateFrom, dateUntil, services, paymentTypes} = this.state.filters;
        let url = `/stats/ticket/detailed/?date_from=${dateFrom}&date_until=${dateUntil}`;
        url += services.map(s => `&service=${s}`).join('');
        url += paymentTypes.map(pt => `&payment_type=${pt}`).join('');
        if (this.state.includeTickets) {
            url += '&tickets=1'
        }

        const token = window.sessionStorage.getItem('token');
        const response = await fetch(url, {
            headers: {'Authorization': `Token ${token}`},
        });
        const responseJson = await response.json();
        this.setState({data: responseJson, isLoading: false});
    };

    onReportTabChange = (tab) => {
        this.setState({includeTickets: tab === 'detailed'}, this.getReportData);
    };

    render() {
        const {data, filters, reference} = this.state;
        return (
            <Container fluid>
                <Row>
                    <Col lg={8} xl={10}>
                        <Report
                            data={data}
                            reference={reference}
                            filters={filters}
                            isLoading={this.state.isLoading}
                            onReportTabChange={this.onReportTabChange}
                        />
                    </Col>
                    <Col lg={4} xl={2}>
                        <ReportFilterForm
                            filters={filters}
                            reference={reference}
                            onChangeFilter={this.onChangeFilter}
                        />
                        <ReportFilterFormSubmitButton
                            disabled={this.state.isLoading}
                            onClick={this.getReportData}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
