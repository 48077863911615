import React from "react";

import {Table} from 'react-bootstrap';

const PaymentsList = ({payments, onSelect}) => {
    const readableDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };

    return (
        <Table striped bordered hover size={'sm'}>
            <thead>
            <tr>
                <th>#</th>
                <th>Сума</th>
                <th>Час</th>
                <th>Кількість квитків</th>
            </tr>
            </thead>
            <tbody>
            {payments.map(payment => (
                <tr
                    key={payment.id}
                    style={{cursor: 'pointer'}}
                    onClick={() => onSelect(payment.id)}
                >
                    <td>{payment.id}</td>
                    <td>{payment.amount}</td>
                    <td>{readableDateTime(payment.timestamp)}</td>
                    <td>{payment.tickets_count}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
};

export default PaymentsList;
