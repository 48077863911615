import React, {memo} from 'react';
import AggregatedReport from './AggregatedReport';

const DinoSofiaReport = ({data, reference, isLoading}) => {
    return (
        <AggregatedReport
            data={data.reports}
            reference={reference}
            isLoading={isLoading}
        />
    )
};

export default memo(DinoSofiaReport);
