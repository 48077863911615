import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Container} from 'react-bootstrap';

import IndexPage from './pages/IndexPage';
import AuthenticationPage from './pages/AuthenticationPage';
import CashierWorkPlace from './pages/CashierWorkPlace';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ReportsPage from './pages/ReportsPage';
import CashierReportPage from './pages/CashierReportPage';
import DinoSofiaReportPage from './pages/DinoSofiaReportsPage';
import UncollectedRegistersReportPage from './pages/UncollectedRegistersReportPage';

import Header from './components/Header';

import {UserContext} from './context';

export default class App extends React.PureComponent {
    state = {
        user: null,
    };

    componentDidMount() {
        const token = window.sessionStorage.getItem('token');
        if (token) {
            this.getUser(token);
        }
    }

    getUser = async (token) => {
        const response = await fetch('/user/', {
            headers: {'Authorization': `Token ${token}`},
        });
        this.setUser(await response.json());
    };

    setUser = (user) => this.setState({user});

    render() {
        const {user} = this.state;
        return (
            <Router>
                <UserContext.Provider value={user}>
                    <Header/>
                    <main>
                        <Container fluid>
                            <Switch>
                                <Route exact path='/' component={IndexPage}/>
                                <Route path='/login' component={AuthenticationPage}/>
                                <Route path='/privacy_policy' component={PrivacyPolicyPage}/>
                                {user && user.permissions.accountant && (
                                    <Route exact path='/reports' component={ReportsPage}/>
                                )}
                                {user && user.permissions.dinosofia_report && (
                                    <Route exact path='/reports/dinosofia' component={DinoSofiaReportPage}/>
                                )}
                                {user && user.permissions.dinosofia_collections && (
                                    <Route exact path='/reports/uncollected_registers' component={UncollectedRegistersReportPage}/>
                                )}
                                {user && user.permissions.cashier && (
                                    <>
                                        <Route path='/cashier' component={CashierWorkPlace}/>
                                        <Route path='/reports/cashier' component={CashierReportPage}/>
                                    </>
                                )}
                            </Switch>
                        </Container>
                    </main>
                </UserContext.Provider>
            </Router>
        )
    }
}
