import React, {memo, useContext} from "react";
import {Col, Form, Row, Button} from "react-bootstrap";

import AutocompleteField from "../AutocompleteField";
import {PaymentReferenceContext} from "../../context";


const FormItem = ({
                    id,
                    visitor,
                    phone,
                    service,
                    amount,
                    children,
                    exemption,
                    comment,
                    excursionAdult,
                    excursionChildren,
                    cashDesk,
                    onFieldChange,
                    onDeleteIconClick
                  }) => {
  const {servicesList, exemptions} = useContext(PaymentReferenceContext);

  const selectedService = servicesList && servicesList.find(s => s.id === service);
  const getAmountFieldPlaceholder = () => {
    if (!selectedService) {
      return 'Кількість';
    }
    switch (selectedService.type) {
      case 'fixed':
        return 'Кількість';
      case 'hourly':
        return 'Кількість годин';
      case 'floating':
        return 'Вартість';
      default:
        return 'Кількість';
    }
  };

  const getServicesSelectOptionsText = (service) => {
    const getSuffix = () => {
      switch (service.type) {
        case 'fixed':
          return 'грн.';
        case 'hourly':
          return 'грн/год.';
        default:
          return '';
      }
    };

    if (service.price === null) {
      return service.full_name;
    }
    return `${service.full_name} (${service.price} ${getSuffix()})`
  };

  const onVisitorSelect = (visitor) => {
    const visitorField = document.getElementById(`visitor-autocomplete-field-${id}`);
    const phoneField = document.getElementById(`phone-autocomplete-field-${id}`);

    visitorField.value = visitor ? visitor.name : '';
    onFieldChange(id, 'visitor', visitor ? visitor.name : '');

    onFieldChange(id, 'phone', visitor ? visitor.phone : '');
    phoneField.value = visitor ? visitor.phone : '';

    visitor && visitor.name ? visitorField.classList.add('is-valid') : visitorField.classList.remove('is-valid');
    visitor && visitor.phone ? phoneField.classList.add('is-valid') : phoneField.classList.remove('is-valid');
  };

  const onVisitorChange = (value) => {
    const visitorField = document.getElementById(`visitor-autocomplete-field-${id}`);
    onFieldChange(id, 'visitor', value || '');
    value ? visitorField.classList.add('is-valid') : visitorField.classList.remove('is-valid');
  };

  const onPhoneChange = (value) => {
    const phoneField = document.getElementById(`phone-autocomplete-field-${id}`);
    onFieldChange(id, 'phone', value || '');
    value ? phoneField.classList.add('is-valid') : phoneField.classList.remove('is-valid');
  };

  const onAutocompleteFieldBlur = e => {
    const {target} = e;
    target.value ? target.classList.add('is-valid') : target.classList.remove('is-valid')
  };

  const requireComment = selectedService && selectedService.require_comment;
  const isFreeService = selectedService && selectedService.is_exemption && selectedService.id !== 22;
  const isExcursionService = selectedService && selectedService.is_excursion;
  const needChildrenField = selectedService && selectedService.can_bind_children_tickets && (selectedService.park === cashDesk.park_id || cashDesk.id === 11);
  return (
    <div style={{padding: '15px 0', borderBottom: '2px solid #E9ECEF'}}>
      <Row>
        <Col md={needChildrenField || isExcursionService ? 7 : 8}>
          <Form.Control
            as="select"
            value={service}
            isValid={service > 0}
            onChange={e => onFieldChange(id, 'service', e.target.value)}
          >
            <option value={0}>Послуга...</option>
            {servicesList && servicesList.map(s => (
              <option
                key={s.id}
                value={s.id}
              >{getServicesSelectOptionsText(s)}</option>
            ))}
          </Form.Control>
        </Col>
        {!isExcursionService && (
          <Col md={needChildrenField ? 2 : 3}>
            <Form.Control
              placeholder={getAmountFieldPlaceholder()}
              value={amount || ''}
              type={'number'}
              isValid={amount > 0}
              onChange={e => onFieldChange(id, 'amount', parseInt(e.target.value))}
              onFocus={e => e.target.select()}
            />
          </Col>
        )}
        {isExcursionService && (
          <>
            <Col md={2}>
              <Form.Control
                placeholder={'Дорослі'}
                value={excursionAdult || ''}
                type={'number'}
                isValid={excursionAdult > 0}
                onChange={e => onFieldChange(id, 'excursionAdult', parseInt(e.target.value)) || 0}
                onFocus={e => e.target.select()}
              />
            </Col>
            <Col md={2}>
              <Form.Control
                placeholder='Діти'
                value={excursionChildren || ''}
                type={'number'}
                isValid={excursionChildren > 0}
                onChange={e => onFieldChange(id, 'excursionChildren', (parseInt(e.target.value)) || 0)}
                onFocus={e => e.target.select()}
              />
            </Col>
          </>
        )}
        {needChildrenField && (
          <Col md={2}>
            <Form.Control
              placeholder='Діти (безкоштовно)'
              value={children || ''}
              type={'number'}
              isValid={children >= 0}
              onChange={e => onFieldChange(id, 'children', (parseInt(e.target.value)) || 0)}
              onFocus={e => e.target.select()}
            />
          </Col>
        )}
        <Col md={1}>
          <Button
            onClick={() => onDeleteIconClick(id)}
            variant="link"
          >&#10060;</Button>
        </Col>
      </Row>
      {isFreeService && (
        <>
          <Row style={{paddingTop: '15px'}}>
            {!requireComment ? (
              <>
                <Col md={5}>
                  <AutocompleteField
                    id={`visitor-autocomplete-field-${id}`}
                    searchUrl='/ticket/visitors/'
                    placeholder='Відвідувач'
                    labelKey={'name'}
                    minLength={2}
                    autoFocus={true}
                    onChange={onVisitorChange}
                    onSelect={onVisitorSelect}
                    onBlur={onAutocompleteFieldBlur}
                  />
                </Col>
                <Col md={3}>
                  <AutocompleteField
                    id={`phone-autocomplete-field-${id}`}
                    searchUrl='/ticket/visitors/'
                    placeholder='Телефон'
                    labelKey={'phone'}
                    minLength={2}
                    value={visitor}
                    isValid={!!phone}
                    onChange={onPhoneChange}
                    onSelect={onVisitorSelect}
                    onBlur={onAutocompleteFieldBlur}
                  />
                </Col>
              </>
            ) : (
              <Col md={9}>
                <Form.Control
                  placeholder='Коментар'
                  value={comment || ''}
                  isValid={comment && comment.length}
                  onChange={e => onFieldChange(id, 'comment', e.target.value)}
                />
              </Col>
            )}
            <Col md={3}>
              <Form.Control
                as="select"
                value={exemption}
                isValid={exemption > 0}
                onChange={e => onFieldChange(id, 'exemption', e.target.value)}
              >
                {exemptions && exemptions.map(e => (
                  <option
                    key={e.id}
                    value={e.id}
                  >{e.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
};

export default memo(FormItem);
