import React, {Fragment, memo} from 'react';
import {Table} from "react-bootstrap";

import Loader from '../Loader';

const AggregatedReport = ({data, reference, filters, isLoading}) => {
    if (!reference.payment_types || !reference.services || !data.tickets_service_report || !data.tickets_payment_type_report) {
        return null;
    }

    const servicesReport = data.tickets_service_report;
    const paymentTypesReport = data.tickets_payment_type_report;
    const cashiersReport = data.tickets_cashiers_report;

    const paymentTypes = reference.payment_types.filter(pt => filters.paymentTypes.includes(pt.id));
    const services = reference.services.filter(s => filters.services.includes(s.id));
    const cashiers = reference.cashiers;

    if (isLoading) {
        return <Loader />;
    }
    return (
        <div
            style={{marginTop: '16px'}}
            className='reports'
        >
            <h5>Сума коштів отримана за реалізовані квитки</h5>
            <Table
                striped
                bordered
                hover
                size="sm"
                style={{marginTop: '16px'}}
                className='payment-types-report'
            >
                <thead>
                <tr>
                    <th/>
                    {paymentTypes.map(pt => <th key={pt.id}>{pt.name}</th>)}
                    <th>Всього</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(paymentTypesReport).map(date => (
                    <tr key={date}>
                        <td>{date}</td>
                        {paymentTypes.map(pt => <td key={pt.id}>{paymentTypesReport[date][pt.id]}</td>)}
                        <td><b>{paymentTypesReport[date]['total']}</b></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <hr/>

            <h5>Готівка касирів</h5>
            <Table
                striped
                bordered
                hover
                size="sm"
                style={{marginTop: '16px'}}
                className='services-report'
            >
                <thead>
                <tr>
                    <th/>
                    {reference.cashiers.map(c => <th key={c.id} colSpan={2}>{c.name}</th>)}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td/>
                    {reference.cashiers.map(c => (
                        <Fragment key={c.id}>
                            <td>Готівка</td>
                            <td>Безготівка</td>
                        </Fragment>
                    ))}
                </tr>
                {Object.keys(cashiersReport).map(date => (
                    <tr key={date}>
                        <td>{date}</td>
                        {cashiers.map(s => (
                            <Fragment key={s.id}>
                                <td>{cashiersReport[date][s.id][1]}</td>
                                <td>{cashiersReport[date][s.id][4]}</td>
                            </Fragment>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
            <hr/>

            <h5>Кількість реалізованих квитків</h5>
            <Table
                striped
                bordered
                hover
                size="sm"
                style={{marginTop: '16px'}}
                className='services-report'
            >
                <thead>
                <tr>
                    <th/>
                    {services.map(s => <th key={s.id}>{s.name}</th>)}
                    <th>Всього</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(servicesReport).map(date => (
                    <tr key={date}>
                        <td>{date}</td>
                        {services.map(s => <td key={s.id}>{servicesReport[date][s.id]}</td>)}
                        <td><b>{servicesReport[date]['total']}</b></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <hr/>
        </div>
    )
};

export default memo(AggregatedReport, (prevProps, nextProps) => prevProps.data === nextProps.data);
