import React, {memo, useState, useContext} from 'react';

import {Route, Switch, useLocation} from "react-router-dom";

import RegisterMenu from "./RegisterMenu";
import PaymentsList from "./PaymentsList";
import PaymentForm from "./PaymentForm";
import PaymentDetails from "./PaymentDetails";
import RegisterBalance from "./RegisterBalance";
import CloseRegisterModal from "./CloseRegisterModal";
import RegisterPrintReport from "./RegisterPrintReport";
import {UserContext} from "../context";
import CheckTicket from "../pages/CheckTicket";

const RegisterPanel = ({
                           register,
                           isProcessingPayment,
                           onNewPaymentButtonPress,
                           onCloseRegisterButtonPress,
                           onPaymentFormSubmit,
                           onCloseRegisterFormConfirm,
                           onPaymentSelect,
                           onXReportButtonPress,
                           onServiceOutgoingFormSubmit,
                           onCancelPayment,
                           onCheckTicketButtonPress,
                       }) => {
    const [showCloseRegisterModal, setShowCloseRegisterModal] = useState(false);
    const [showServiceOutgoingModal, setShowServiceOutgoingModal] = useState(false);

    const user = useContext(UserContext);

    let location = useLocation();

    const _onCloseRegisterButtonPress = async () => {
        await onCloseRegisterButtonPress();
        setShowCloseRegisterModal(true);
    };

    const _onCloseRegisterFormConfirm = (finishBalance) => {
        setShowCloseRegisterModal(false);
        onCloseRegisterFormConfirm(finishBalance, user.id);
    };

    const _onServiceOutgoingFormConfirm = (balance) => {
        setShowServiceOutgoingModal(false);
        onServiceOutgoingFormSubmit(balance, user.id);
    };

    return (
        <>
            {location.pathname === '/cashier' && (
                <RegisterPrintReport register={register}/>
            )}
            <RegisterMenu
                register={register}
                onNewPaymentButtonPress={onNewPaymentButtonPress}
                onCloseRegisterButtonPress={_onCloseRegisterButtonPress}
                onXReportButtonPress={onXReportButtonPress}
                onServiceOutgoingButtonpress={() => setShowServiceOutgoingModal(true)}
                onCheckTicketButtonPress={onCheckTicketButtonPress}
            />
            <CloseRegisterModal
                show={showCloseRegisterModal}
                onHide={() => setShowCloseRegisterModal(false)}
                onCloseRegisterFormConfirm={_onCloseRegisterFormConfirm}
            />
            <CloseRegisterModal
                show={showServiceOutgoingModal}
                onHide={() => setShowServiceOutgoingModal(false)}
                onCloseRegisterFormConfirm={_onServiceOutgoingFormConfirm}
            />
            <Switch>
                <Route exact path='/cashier' render={() => (
                    <>
                        <RegisterBalance balance={register.balance}/>
                        <PaymentsList
                            payments={register.payments}
                            onSelect={onPaymentSelect}
                        />
                    </>
                )}/>
                <Route exact path='/cashier/payment' render={() => (
                    <PaymentForm
                        cashDesk={register.cash_desk}
                        isProcessingPayment={isProcessingPayment}
                        onSubmit={onPaymentFormSubmit}
                    />
                )}
                />
                <Route path='/cashier/payment/:id' render={props => (
                    <PaymentDetails
                        paymentId={parseInt(props.match.params.id)}
                        onCancelPayment={onCancelPayment}
                    />
                )}
                />
                <Route path='/cashier/check/ticket' render={props => (
                    <CheckTicket />
                )}
                />
            </Switch>
        </>
    )
};

export default memo(RegisterPanel);