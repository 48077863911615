import React, {memo} from "react";
import {Form} from "react-bootstrap";

const ReportFilterForm = ({filters, reference, onChangeFilter}) => {
    const onChangeDateFrom = (e) => onChangeFilter({...filters, dateFrom: e.target.value});

    const onChangeDateUntil = (e) => onChangeFilter({...filters, dateUntil: e.target.value});

    const onServicesFilterChange = (serviceId, checked) => {
        let services;
        if (checked) {
            services = [...filters.services, parseInt(serviceId)];
        } else if (!checked) {
            services = filters.services.filter(s => s !== parseInt(serviceId));
        }
        onChangeFilter({...filters, services});
    }

    const onPaymentTypeFilterChange = (paymentTypeId, checked) => {
        let paymentTypes;
        if (checked) {
            paymentTypes = [...filters.paymentTypes, parseInt(paymentTypeId)];
        } else if (!checked) {
            paymentTypes = filters.paymentTypes.filter(s => s !== parseInt(paymentTypeId));
        }
        onChangeFilter({...filters, paymentTypes});
    };

    return (
        <div>
            <Form>
                <Form.Group>
                    <Form.Label htmlFor='report-date-from'>Від:</Form.Label>
                    <Form.Control
                        placeholder='Від'
                        type={'date'}
                        value={filters.dateFrom}
                        id='report-date-from'
                        min='2020-07-06'
                        max='2130-12-31'
                        onChange={onChangeDateFrom}
                    />
                </Form.Group>
                <hr/>
                <Form.Group>
                    <Form.Label htmlFor='reports-date-until'>До:</Form.Label>
                    <Form.Control
                        placeholder='До'
                        type={'date'}
                        value={filters.dateUntil}
                        id='report-date-until'
                        min='2020-07-06'
                        max='2130-12-31'
                        onChange={onChangeDateUntil}
                    />
                </Form.Group>
                <hr/>
                <Form.Group>
                    <Form.Label htmlFor='reports-date-until'>Послуги:</Form.Label>
                    {reference.services && reference.services.map(s => (
                        <Form.Check
                            defaultChecked={filters.services.includes(s.id)}
                            key={s.id}
                            label={s.name}
                            value={s.id}
                            type='switch'
                            id={`service-checkbox-${s.id}`}
                            onChange={e => onServicesFilterChange(s.id, e.target.checked)}
                        />
                    ))}
                </Form.Group>
                <hr/>
                <Form.Group>
                    <Form.Label htmlFor='reports-date-until'>Тип платежу:</Form.Label>
                    {reference.payment_types && reference.payment_types.map(pt => (
                        <Form.Check
                            defaultChecked={filters.paymentTypes.includes(pt.id)}
                            key={pt.id}
                            label={pt.name}
                            value={pt.id}
                            type='switch'
                            id={`payment-type-checkbox-${pt.id}`}
                            onChange={e => onPaymentTypeFilterChange(pt.id, e.target.checked)}
                        />
                    ))}
                </Form.Group>
                <hr/>
            </Form>
        </div>
    )
};

export default memo(ReportFilterForm);
