import React, {useContext, useEffect, memo} from "react";

import {useLocation, useHistory} from 'react-router-dom'

import {
  Navbar,
} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav'
import {LinkContainer} from 'react-router-bootstrap';
import {UserContext} from "../context";

const Header = () => {
  let location = useLocation();
  let history = useHistory();
  let token = window.sessionStorage.getItem('token');
  const user = useContext(UserContext);

  const logout = () => {
    window.sessionStorage.clear();
    history.replace('/');
  };

  useEffect(() => {
    if (user && user.permissions.dinosofia_collections) {
      location.pathname === '/' && history.replace('/reports/uncollected_registers')
    } else if (user && user.permissions.cashier) {
      location.pathname === '/' && history.replace('/cashier');
    }
  }, [user]);

  const toLoginPage = () => history.replace('/login');

  return (
    <Navbar bg="dark" variant="dark">
      <LinkContainer to='/'>
        <Navbar.Brand>Софіївка</Navbar.Brand>
      </LinkContainer>

      {user && token && (
        <Nav className="mr-auto">
          {user.permissions.cashier && (
            <>
              <a
                href="/cashier"
                className={location.pathname === '/cashier' ? 'nav-link active' : 'nav-link'}
              >Робоче місце касира</a>
              <a
                href="/reports/cashier"
                className={location.pathname === '/reports/cashier' ? 'nav-link active' : 'nav-link'}
              >Звіт касира</a>
            </>
          )}
          {user.permissions.accountant && (
            <LinkContainer to='/reports'>
              <Nav.Link active={location.pathname === '/reports'}>Звіт "Софіївка"</Nav.Link>
            </LinkContainer>
          )}
          {/*{user.permissions.dinosofia_report && (*/}
          {/*  <LinkContainer to='/reports/dinosofia'>*/}
          {/*    <Nav.Link active={location.pathname === '/reports/dinosofia'}>Звіт "DinoSofia"</Nav.Link>*/}
          {/*  </LinkContainer>*/}
          {/*)}*/}
        </Nav>
      )}
      <Navbar.Collapse className="justify-content-end">
        {token && user && (
          <Navbar.Text>
            <a
              style={{paddingRight: '15px'}}
            >{user.name}</a>
          </Navbar.Text>
        )}
        <Navbar.Text>
          <a
            style={{cursor: 'pointer'}}
            onClick={token ? logout : toLoginPage}
          >{token ? 'Вихід' : 'Увійти'}</a>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default memo(Header, () => true);
