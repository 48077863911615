import React, {memo} from 'react';
import {Button} from "react-bootstrap";

const SubmitFormButton = ({disabled, onClick}) => (
    <div className='d-flex justify-content-end' style={{paddingTop: '15px'}}>
        <Button
            variant="outline-secondary"
            disabled={disabled}
            onClick={onClick}
        >Підтвердити</Button>
    </div>
);

export default memo(SubmitFormButton);
