import React, {useState, memo} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";

const CloseRegisterModal = ({show, onHide, onCloseRegisterFormConfirm}) => {
    const [finishCashBalance, setFinishCashBalance] = useState(0);
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Введіть суму готівки, що буде здана</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Control
                            autoFocus={true}
                            value={finishCashBalance || '0'}
                            type={'number'}
                            isValid={finishCashBalance && finishCashBalance >= 0}
                            onChange={e => setFinishCashBalance(parseInt(e.target.value))}
                            onFocus={e => e.target.select()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{paddingTop: '8px'}}>
                        <Button
                            variant='outline-secondary'
                            disabled={finishCashBalance == null || finishCashBalance < 0}
                            onClick={() => onCloseRegisterFormConfirm(finishCashBalance)}
                        >Підтвердити</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default memo(CloseRegisterModal);
