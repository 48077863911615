import React from "react";

import Loader from '../components/Loader';
import RegisterCreationPanel from '../components/RegisterCreationPanel';
import RegisterPanel from '../components/RegisterPanel';
import ErrorModalWindow from '../components/ErrorModalWindow';

import {PaymentReferenceContext} from '../context';

const wsUrl = 'wss://async.sofiivka.uman.ua';
// const wsUrl = 'ws://127.0.0.1:8765';

class CashierWorkPlace extends React.PureComponent {
    state = {
        isLoading: true,
        isProcessingPayment: false,
        register: null,
        token: window.sessionStorage.getItem('token'),
        error: null,
        posData: null,
        reference: {},
    };

    componentDidMount() {
        if (this.state.token) {
            this.getRegister();
            this.getPaymentsReference();
            document.title = 'Робоче місце касира - Софіївка'
        } else {
            this.props.history.replace('/login');
        }

        this.fetchPaymentsReferenceInterval = setInterval(this.getPaymentsReference, 60 * 5 * 1000);
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
    }

    componentWillUnmount() {
        clearInterval(this.fetchPaymentsReferenceInterval);
    }

    getRegister = async () => {
        const response = await fetch('/payment/cash/register/', {
            headers: {'Authorization': `Token ${this.state.token}`},
        });
        const responseJson = await response.json();
        if (response.status === 200) {
            this.setState({isLoading: false, register: responseJson});
        } else if (response.status === 404) {
            this.setState({isLoading: false, register: null});
        } else if (response.status === 401) {
            this.setState(
                {isLoading: false, register: null},
                () => this.props.history.replace('/login'));
        } else {
            this.setState({isLoading: false, error: responseJson});
        }
    };

    getPaymentsReference = async () => {
        const url = `/payment/cash/reference/?cash_desk_id=${localStorage.getItem('каса')}`;
        const response = await fetch(url, {
            headers: {'Authorization': `Token ${this.state.token}`},
        });
        const responseJson = await response.json();
        this.setState({
            reference: {
                servicesList: responseJson.services,
                serviceSets: responseJson.service_sets,
                exemptions: responseJson.exemptions,
                paymentTypes: responseJson.payment_types,
            }
        });
    };

    createNewRegister = async (initialBalance, userId) => {
        if (!window.confirm('Підтвердіть відкриття реєстру.')) return;
        this.setState({isLoading: true});

        const self = this;
        const {token} = this.state;
        const ws = new WebSocket(`${wsUrl}/create_register`);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                cash_desk: localStorage.getItem('каса'),
                initial_balance: initialBalance,
                user_id: userId,
                token,
            }));
        };

        ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

        ws.onmessage = function (evt) {
            const message = JSON.parse(evt.data);
            if (message.error) {
                self.setState({isLoading: false, error: message.error});
            } else {
                self.setState({isLoading: false, register: message.result});
            }
            ws.close(1000, 'OK');
        };
    };

    onStartCloseRegister = async () => {
        this.props.history.replace('/cashier');
        await this.getRegister();
    };

    closeRegister = async (accountedBalance, userId) => {
        this.setState({isLoading: true});

        const self = this;
        const {token} = this.state;
        const ws = new WebSocket(`${wsUrl}/close_register`);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                cash_desk: localStorage.getItem('каса'),
                finish_cash_balance: accountedBalance,
                user_id: userId,
                token,
            }));
        };

        ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

        ws.onmessage = function (evt) {
            const message = JSON.parse(evt.data);
            if (message.error) {
                self.setState({isLoading: false, error: message.error});
            } else {
                self.setState({register: {...self.state.register, accountedBalance: accountedBalance}}, () => {
                    self.printRegisterReport();
                    self.setState({register: null, isLoading: false});
                });
            }
            ws.close(1000, 'OK');
        };
    };

    makeServiceOutgoing = async (balance, userId) => {
        this.props.history.replace('/cashier');
        this.setState({isLoading: true});

        const self = this;
        const {token} = this.state;
        const ws = new WebSocket(`${wsUrl}/service_outgoing`);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                cash_desk: localStorage.getItem('каса'),
                service_outgoing: balance,
                user_id: userId,
                token,
            }));
        };

        ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

        ws.onmessage = function (evt) {
            const message = JSON.parse(evt.data);
            if (message.error) {
                self.setState({isLoading: false, error: message.error});
            } else {
                window.location.reload();
            }
            ws.close(1000, 'OK');
        };
    };

    cancelPayment = async (paymentId) => {
        if (window.confirm('Ви дійсно бажаєте анулювати платіж?')) {
            this.setState({isLoading: true});

            const self = this;
            const {token} = this.state;
            const ws = new WebSocket(`${wsUrl}/cancel_payment`);
            ws.onopen = () => {
                ws.send(JSON.stringify({
                    cash_desk: localStorage.getItem('каса'),
                    payment_id: paymentId,
                    token,
                }));
            };

            ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

            ws.onmessage = function (evt) {
                const message = JSON.parse(evt.data);
                if (message.error) {
                    self.setState({isLoading: false, error: message.error});
                } else {
                    self.setState({isLoading: false});
                }
                ws.close(1000, 'OK');
            };
        }
    };

    printRegisterReport = () => window.print();

    openNewPaymentForm = () => this.props.history.replace('/cashier/payment');

    onPaymentSelect = (paymentId) => this.props.history.replace(`/cashier/payment/${paymentId}`);

    onCheckTicketButtonPress = () =>  this.props.history.replace('/cashier/check/ticket');

    addPayment = async (data) => {
        try {
            return await this.processNewPayment(data);
        } catch (e) {
            this.setState({error: 'Виникла помилка під час роботи запиту'});
            return false;
        }
    };

    processNewPayment = async (data) => {
        this.setState({isProcessingPayment: true});
        if (this.state.posData === null && data.payment_type === 4) {
            this.processPosTerminalPayment(data);
        } else {
            this.processCashPayment(data, this.state.posData);
        }
    };

    processPosTerminalPayment = (data) => {
        const self = this;
        const {token} = this.state;
        const ws = new WebSocket(`${wsUrl}/make_pos_transaction`);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                cash_desk: localStorage.getItem('каса'),
                token,
                ...data,
            }));
        };

        ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

        ws.onmessage = function (evt) {
            const message = JSON.parse(evt.data);
            if (message.error) {
                self.setState({error: message.error, isProcessingPayment: false});
            } else {
                const posData = message.result;
                self.setState({posData},
                  () => self.processCashPayment({...data}, posData),
                );
            }
            ws.close(1000, 'OK');
        };
    };

    processCashPayment = (data, posData) => {
        const self = this;
        const {token} = this.state;
        const ws = new WebSocket(`${wsUrl}/make_payment`);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                cash_desk: localStorage.getItem('каса'),
                token,
                ...data,
                pos_data: posData,
            }));
        };

        ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

        ws.onmessage = function (evt) {
            const message = JSON.parse(evt.data);
            if (message.error) {
                self.setState({isProcessingPayment: false, error: message.error});
            } else {
                self.setState({isProcessingPayment: false});
                self.onSuccessPayment(message.result);
            }
            ws.close(1000, 'OK');
        };
    };

    onSuccessPayment = (payment) => {
        const {balance} = this.state.register;
        if (balance[payment.type]) {
            balance[payment.type] += parseInt(payment.amount);
        } else {
            balance[payment.type] = parseInt(payment.amount);
        }
        this.setState(state => ({
            posData: null,
            register: {
                ...state.register,
                balance,
                payments: [payment, ...state.register.payments],
            }
        }), () => this.props.history.replace(`/cashier/payment/${payment.id}?print=1`));
    };

    printXReport = () => {
        const self = this;
        const {token} = this.state;
        const ws = new WebSocket(`${wsUrl}/x_report`);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                cash_desk: localStorage.getItem('каса'),
                token,
            }));
        };

        ws.onerror = () => self.setState({isProcessingPayment: false, error:  'Помилка роботи запиту.'});

        ws.onmessage = function (evt) {
            const message = JSON.parse(evt.data);
            if (message.error) {
                self.setState({error: message.error});
            }
            ws.close(1000, 'OK');
        };
    }

    closeErrorModal = () => this.setState({error: null});

    render() {
        const {isLoading, isProcessingPayment, register, error, posData} = this.state;
        if (isLoading) return <Loader/>;
        return (
            <div className='cashier-work-place'>
                {!isProcessingPayment && posData && (
                  <span style={{color: 'red', fontSize: '16px'}}>
                      Незавершений безготівковий платіж #{this.state.posData.rrn}
                  </span>
                )}
                <ErrorModalWindow
                    text={error}
                    show={error !== null}
                    onHide={this.closeErrorModal}
                />
                {register ? (
                    <PaymentReferenceContext.Provider value={this.state.reference}>
                        <RegisterPanel
                            register={register}
                            isProcessingPayment={isProcessingPayment}
                            onNewPaymentButtonPress={this.openNewPaymentForm}
                            onCloseRegisterButtonPress={this.onStartCloseRegister}
                            onCloseRegisterFormConfirm={this.closeRegister}
                            onPaymentSelect={this.onPaymentSelect}
                            onPaymentFormSubmit={this.addPayment}
                            onXReportButtonPress={this.printXReport}
                            onServiceOutgoingFormSubmit={this.makeServiceOutgoing}
                            onCancelPayment={this.cancelPayment}
                            onCheckTicketButtonPress={this.onCheckTicketButtonPress}
                        />
                    </PaymentReferenceContext.Provider>
                ) : (
                    <RegisterCreationPanel onCreateButtonClick={this.createNewRegister}/>
                )}
            </div>
        )
    }
}

export default CashierWorkPlace;
