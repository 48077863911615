import React, {memo} from 'react';

import {Modal} from 'react-bootstrap';

const ErrorModalWindow = ({text, show, onHide}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Помилка.</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{text}</p>
        </Modal.Body>
    </Modal>
);

export default memo(ErrorModalWindow);
