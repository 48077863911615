import React, {memo} from 'react';
import {Button, ButtonGroup} from "react-bootstrap";


const TicketMenu = ({showCancelButton, onPrintButtonPress, onCancelTicketPress}) => {
    return (
        <div className='ticket-menu'>
            <ButtonGroup size="sm">
                <Button
                    variant="outline-secondary"
                    onClick={onPrintButtonPress}
                >Друк</Button>
                {showCancelButton && (
                    <Button
                        variant="outline-secondary"
                        onClick={onCancelTicketPress}
                    >Ануляція</Button>
                )}
            </ButtonGroup>
        </div>
    )
}

export default memo(TicketMenu, () => true);
